/* istanbul ignore file */
import { graphql } from "gatsby";
import React from "react";
import { ImageDataFragment } from "../../../graphql-types";
import GatsbyImage from "../GatsbyImage";

type Props = ImageDataFragment;

export default function Image({ asset }: Props) {
  return (
    <GatsbyImage
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      alt={asset.description!}
      image={asset.gatsbyImageData}
      // If this is the first element, assume it's a full-width hero
      className="w-full first:max-w-container mx-auto first:px-0 max-w-[calc(100%-2rem)] sm:max-w-lg"
    />
  );
}

export const fragment = graphql`
  fragment ImageData on Image {
    asset {
      description
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`;
